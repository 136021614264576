import {Box, Typography} from "@mui/material";
import {ReactComponent as PaymentImg} from "../../../assets/images/payment.svg";
import React from "react";
import {makeStyles} from "@mui/styles";
import {SECTION_ID} from "../mappings";
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../../../store/reducers/tenant.reducer'
import { COMPANIES } from '../../../hooks/useCompany'

export const IntroduceSection = () => {
    const classes = useStyles()
    const tenant = useSelector(selectTenantConfig)

    const getDescription = () => {
        if (tenant.companyName === COMPANIES.ZERO_LEDGER) {
            return 'New Age Merchant Payments Solutions\nHarnessing the Power Blockchain Technology'
        }
        return 'We are a new age technology company whose mission is to enable financial and non-financial institutions to securely manage their digital commerce business and capitalize on the burgeoning digital payments.'
    }

    const getCompanyName = () => {
        if (tenant.companyName === COMPANIES.THE_ZERO_WALLET) {
            return 'ZeroWallet'
        }
        if (tenant.companyName === COMPANIES.ZERO_LEDGER) {
            return 'ZeroLedger'
        }
        return tenant.companyName
    }

    return (
        <Box className={classes.introduceSection} id={SECTION_ID.HOME}>
            <Box className={classes.rotatedText}>
                <Typography className={classes.title}>
                    Welcome to {getCompanyName()}
                </Typography>
            </Box>
            <Typography className={classes.weHelpText}>
                {getDescription()}
            </Typography>
            <PaymentImg className={classes.paymentImg} />
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    introduceSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    rotatedText: {
        transform: 'rotate(-4deg)',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down(1260)]: {
            textAlign: 'center',
            alignItems: 'center',
        },
    },
    weHelpText: {
        marginTop: '220px!important',
        width: '100%',
        maxWidth: 700,
        fontWeight: '600!important',
        lineHeight: '1.8!important',
        fontSize: '18px!important',
        color: `${theme.palette.primary.main}!important`,
        textAlign: 'center',
        whiteSpace: 'break-spaces',
        alignSelf: 'center',
        [theme.breakpoints.down(1260)]: {
            marginTop: '72px!important',
        },
    },
    paymentImg: {
        [theme.breakpoints.down(1260)]: {
            display: 'none',
        },
        position: 'absolute',
        top: 64,
        right: 174,
    },
    simplifyText: {
        '&.MuiTypography-root': {
            marginTop: 20,
            fontSize: 24,
            color: '#58585A',
            width: '100%',
            maxWidth: 480,
        },
    },
    title: {
        '&.MuiTypography-root': {
            fontFamily: 'Jeko-Black',
            fontSize: 112,
            lineHeight: '89px',
            width: '100%',
            maxWidth: 680,
            marginTop: 122,
            [theme.breakpoints.down(1260)]: {
                alignSelf: 'center',
                fontSize: 98,
                lineHeight: '80px',
                marginTop: 82,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 48,
                maxWidth: 680,
                marginTop: 24,
                lineHeight: '40px',
            },
        },
    },
}))
