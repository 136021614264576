import React from 'react'
import {Box, CircularProgress, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { selectTenantConfig } from '../../store/reducers/tenant.reducer'
import { useSelector } from 'react-redux'
import { COMPANIES } from '../../hooks/useCompany'

const FullscreenLoader = () => {
  const classes = useStyles()
  const tenantConfig = useSelector(selectTenantConfig)

  const renderCompanyImg = () => {
    if (tenantConfig.companyName === COMPANIES.ZERO_LEDGER) {
      return COMPANIES.THE_ZERO_WALLET
    }
    if (tenantConfig.companyName === COMPANIES.THE_ZERO_WALLET) {
      return COMPANIES.ZERO_LEDGER
    }
    return tenantConfig.companyName
  }

  return (
    <Box className={classes.loaderWrapper}>
      {/*<Typography fontSize={23} fontWeight={'bold'} color="#000">*/}
      {/*  {renderCompanyImg()}*/}
      {/*</Typography>*/}
      {tenantConfig?.logoUrl ? (
        <img className={classes.loaderImg} src={tenantConfig.logoUrl} alt={tenantConfig.companyName}/>
      ): (
        <Typography fontSize={23} fontWeight={'bold'} color="#000">
        {tenantConfig.companyName}
      </Typography>)}
      <CircularProgress/>
    </Box>
  )
}

const useStyles = makeStyles({
  loaderWrapper: {
    width: '100%',
    height: '100vh',
    gap: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderImg: {
    maxWidth: 125,
    objectFit: 'contain',
  }
})

export default FullscreenLoader
